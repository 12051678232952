.news{
    .news__row{
        .news__link{
            color:$dark-primary-color;
            font-style: italic;
            text-align: center;
            text-decoration: underline;
            margin-top: 28px;
            margin-bottom: 18px;
            display: block;
        }
    }
    .news-list{
        display: flex;
        flex-wrap: wrap;
        margin-top: 27px;
        .news-list__item{
            flex: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            margin-bottom: 63px;
            &:nth-child(3),&:nth-child(4){
                margin-bottom: 0;
            }
            .news-list__content{
                margin-left: 15px;
            }
            .news-list__date{
                time{
                    text-align: left;
                    color:$dark-primary-color;
                    font-style: italic;
                }
            }
            .news-list__title{
                height: 60px;
                h4{
                    margin-top: 24px;
                    margin-bottom: 12px;
                    font-size: 14px;
                }
            }
        }
    }
}