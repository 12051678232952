.services-list__svg{
    path{
        fill: red;
        background-color: red;
    }
}
.services{
    padding:25px 0;
    padding-bottom: 0;
    .services-list{
        display: flex;
        flex-wrap: wrap;
    }
    .services-list__item{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        flex: 33%;
        .services-list__img{
            filter: invert(99%) sepia(42%) saturate(7000%) hue-rotate(313deg) brightness(88%) contrast(86%);
        }
        margin-bottom: 35px;
        &:hover{
            cursor: pointer;
            .services-list__title{
                h4{
                    color:$primary-color;
                }
            }
            .services-list__img{
                filter: invert(25%) sepia(15%) saturate(2254%) hue-rotate(185deg) brightness(93%) contrast(94%);
            }
        }
    }
    .services-list__title{
        margin-top: 20px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        text-align: center;
        h4{
            transition: 0.3s all;
        }
    }
}