body{
    font-family: Arial, Helvetica, sans-serif;
}
*{
    color: $text-color;
    font-size: 13px;
}
img.svg{
    g{
        fill: red;
    }
}
.header__logo.small{
    display:none;
}
.title{
    font-size: 28px;
}
.wrapper{
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.content{
    flex:1 1 auto;
}
.section{
    margin:45px 0;
    position: relative;
    border: 1px solid $border-color_one;
    padding:0 25px;
    padding-right: 18px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &.map{
        border-left: none;

        border-right: none;
    }
    &.partners{
        display: block;
        padding:80px 0;
       .partners_row{
           display: block;
       }
        .section{
            text-align: center;
        }
    }
    .section-title{
        background-color:#fff;
        padding: 0 20px;
        top: -8px;
        font-weight: bold;
        position: absolute;
        text-transform: uppercase;
        font-size: 15px;
    }
}
.text{
    font-size: 14px;
    line-height: 18px;
}
.read_more{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    width: 155px;
    background:$primary-color;
    color: #fff;
    font-size: 14px;
    transition: 0.3s all;
    &:hover{
        background-color: $subs-btn;
    }
}
.container{
    width: 1240px;
    margin:0 auto;
}
.header__row{
    display:flex;
    justify-content: space-between;
}
.main-nav{
    display: flex;
    .main-list{
        display: inline-flex;
        .main-list__item{
            position: relative;
            padding: 20px 32px;
            background: $primary-color;
            transition: 0.3s all;
            &.not_empty::after{
                font-family: FontAwesome;
                content:'\f107 ';
                font-weight: 600;
                margin-left: 5px;
            }
            a{
                font-size: 15px;
                text-transform: capitalize;
            }
            &:hover{
                background-color: $dark_primary-color;
                &.not_empty::after{
                    color: #fff;
                }
                a{
                    color:#fff;
                }
                .main-sublist{
                    display: block;
                }
            }
            .main-sublist{
                position: absolute;
                top:57px;
                left: 0;
                background-color: #fff;
                border: 1px solid #d1ac56;
                z-index: 5;
                width:320px;
                padding:20px 20px 20px 22px;
                display: none;
                transition: .3s all;
               .main-sublist__item{
                   display: flex;
                   align-items: center;
                   margin-bottom: 15px;
                   &:hover{
                       a{
                           color: $dark_primary-color;
                       }
                   }
                   &:last-child{
                       margin-bottom: 0;
                   }
                   a{
                    color:#3d3d3d;
                    font-size:13px;
                    transition: .3s all;
                   }
                &:before{
                    position: absolute;
                    content:'';
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    left:10px;
                    background-color: $primary-color;
                }
               }
            }
        }
    }

}
.header__content{
    display: flex;
}
.header__info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    >div{
        flex:1;
    }
}
.header{
    position: relative;
}
.burger-mobile{
    position: absolute;
    display: none;
    right: 30px;
    top: 15px;
    &:hover{
        cursor:pointer;
    }
    .line-wrapper{
        display:flex;
        flex-direction: column;
        justify-content: space-between;       
        align-items: center;
        height: 25px;
        width: 35px;
        .line{
            height: 3px;
            width: 100%;
            background-color: $primary-color;
        }
    }
}
.main-sublist{
    display: none;
}
.subscribe{
    background-color: $subs-btn;
    padding: 20px 44px;
    font-size: 14px;
    color:#fff;
    text-transform: uppercase;
}
.contact-block{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .contact-block__item{
        margin-left: 69px;
        display:flex;
        justify-content: flex-start;
        align-items: flex-end;

        a{
            margin-left: 10px;
        }
        &:first-child{
            margin-left: 0;
        }
        .phone_number{
            span{
                font-size: 24px;
            }
        }
    }
}
.slick-next::before,.slick-prev::before{
    display: block;
    mask:url(/img/right.svg) no-repeat center;
    background:$primary-color;
    content:'';
    height:50px;
    width: 50px;
}
.slick-prev::before{
    mask:url(/img/left.svg) no-repeat center;
}
.slick-next{
    right: 50px;
}
.slick-prev{
    left: 50px;
}
.slick-next,.slick-prev{
    width:50px;
    height:50px;
}