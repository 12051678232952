@charset "UTF-8";
/*Обнуление*/
* {
  padding: 0;
  margin: 0;
  border: 0; }

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

:focus, :active {
  outline: none; }

a:focus, a:active {
  outline: none; }

nav, footer, header, aside {
  display: block; }

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

input, button, textarea {
  font-family: inherit; }

input::-ms-clear {
  display: none; }

button {
  cursor: pointer; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

a, a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none; }

ul li {
  list-style: none; }

img {
  vertical-align: top; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit; }

/*--------------------*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: 0; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:after, .slick-track:before {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir=rtl] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*# sourceMappingURL=slick.min.css.map */
.slick-loading .slick-list {
  background: #fff url(ajax-loader.gif) center center no-repeat; }

@font-face {
  font-family: slick;
  font-weight: 400;
  font-style: normal;
  src: url(fonts/slick.eot);
  src: url(fonts/slick.eot?#iefix) format("embedded-opentype"), url(fonts/slick.woff) format("woff"), url(fonts/slick.ttf) format("truetype"), url(fonts/slick.svg#slick) format("svg"); }

.slick-next, .slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0; }

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0; }

.slick-next:focus:before, .slick-next:hover:before, .slick-prev:focus:before, .slick-prev:hover:before {
  opacity: 1; }

.slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {
  opacity: .25; }

.slick-next:before, .slick-prev:before {
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: 'тЖР'; }

[dir=rtl] .slick-prev:before {
  content: 'тЖТ'; }

.slick-next {
  right: -25px; }

[dir=rtl] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: 'тЖТ'; }

[dir=rtl] .slick-next:before {
  content: 'тЖР'; }

.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0; }

.slick-dots li button:focus, .slick-dots li button:hover {
  outline: 0; }

.slick-dots li button:focus:before, .slick-dots li button:hover:before {
  opacity: 1; }

.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: 'тАв';
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000; }

/*# sourceMappingURL=slick-theme.min.css.map */
body {
  font-family: Arial, Helvetica, sans-serif; }

* {
  color: #2f3136;
  font-size: 13px; }

img.svg g {
  fill: red; }

.header__logo.small {
  display: none; }

.title {
  font-size: 28px; }

.wrapper {
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.content {
  flex: 1 1 auto; }

.section {
  margin: 45px 0;
  position: relative;
  border: 1px solid rgba(195, 159, 75, 0.75);
  padding: 0 25px;
  padding-right: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .section.map {
    border-left: none;
    border-right: none; }
  .section.partners {
    display: block;
    padding: 80px 0; }
    .section.partners .partners_row {
      display: block; }
    .section.partners .section {
      text-align: center; }
  .section .section-title {
    background-color: #fff;
    padding: 0 20px;
    top: -8px;
    font-weight: bold;
    position: absolute;
    text-transform: uppercase;
    font-size: 15px; }

.text {
  font-size: 14px;
  line-height: 18px; }

.read_more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 155px;
  background: #d1ac56;
  color: #fff;
  font-size: 14px;
  transition: 0.3s all; }
  .read_more:hover {
    background-color: #2d437a; }

.container {
  width: 1240px;
  margin: 0 auto; }

.header__row {
  display: flex;
  justify-content: space-between; }

.main-nav {
  display: flex; }
  .main-nav .main-list {
    display: inline-flex; }
    .main-nav .main-list .main-list__item {
      position: relative;
      padding: 20px 32px;
      background: #d1ac56;
      transition: 0.3s all; }
      .main-nav .main-list .main-list__item.not_empty::after {
        font-family: FontAwesome;
        content: '\f107 ';
        font-weight: 600;
        margin-left: 5px; }
      .main-nav .main-list .main-list__item a {
        font-size: 15px;
        text-transform: capitalize; }
      .main-nav .main-list .main-list__item:hover {
        background-color: #b89347; }
        .main-nav .main-list .main-list__item:hover.not_empty::after {
          color: #fff; }
        .main-nav .main-list .main-list__item:hover a {
          color: #fff; }
        .main-nav .main-list .main-list__item:hover .main-sublist {
          display: block; }
      .main-nav .main-list .main-list__item .main-sublist {
        position: absolute;
        top: 57px;
        left: 0;
        background-color: #fff;
        border: 1px solid #d1ac56;
        z-index: 5;
        width: 320px;
        padding: 20px 20px 20px 22px;
        display: none;
        transition: .3s all; }
        .main-nav .main-list .main-list__item .main-sublist .main-sublist__item {
          display: flex;
          align-items: center;
          margin-bottom: 15px; }
          .main-nav .main-list .main-list__item .main-sublist .main-sublist__item:hover a {
            color: #b89347; }
          .main-nav .main-list .main-list__item .main-sublist .main-sublist__item:last-child {
            margin-bottom: 0; }
          .main-nav .main-list .main-list__item .main-sublist .main-sublist__item a {
            color: #3d3d3d;
            font-size: 13px;
            transition: .3s all; }
          .main-nav .main-list .main-list__item .main-sublist .main-sublist__item:before {
            position: absolute;
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            left: 10px;
            background-color: #d1ac56; }

.header__content {
  display: flex; }

.header__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .header__info > div {
    flex: 1; }

.header {
  position: relative; }

.burger-mobile {
  position: absolute;
  display: none;
  right: 30px;
  top: 15px; }
  .burger-mobile:hover {
    cursor: pointer; }
  .burger-mobile .line-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    width: 35px; }
    .burger-mobile .line-wrapper .line {
      height: 3px;
      width: 100%;
      background-color: #d1ac56; }

.main-sublist {
  display: none; }

.subscribe {
  background-color: #2d437a;
  padding: 20px 44px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase; }

.contact-block {
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .contact-block .contact-block__item {
    margin-left: 69px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end; }
    .contact-block .contact-block__item a {
      margin-left: 10px; }
    .contact-block .contact-block__item:first-child {
      margin-left: 0; }
    .contact-block .contact-block__item .phone_number span {
      font-size: 24px; }

.slick-next::before, .slick-prev::before {
  display: block;
  mask: url(/img/right.svg) no-repeat center;
  background: #d1ac56;
  content: '';
  height: 50px;
  width: 50px; }

.slick-prev::before {
  mask: url(/img/left.svg) no-repeat center; }

.slick-next {
  right: 50px; }

.slick-prev {
  left: 50px; }

.slick-next, .slick-prev {
  width: 50px;
  height: 50px; }

.about .about__row {
  padding-top: 50px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between; }

.about .about__content {
  padding-left: 27px; }
  .about .about__content .about__img {
    max-width: 100%; }
  .about .about__content .about__title {
    margin-bottom: 15px; }
  .about .about__content .quote {
    color: #b89347;
    font-size: 15px;
    font-weight: 700;
    font-style: italic;
    margin: 22px 0;
    display: block; }

.services-list__svg path {
  fill: red;
  background-color: red; }

.services {
  padding: 25px 0;
  padding-bottom: 0; }
  .services .services-list {
    display: flex;
    flex-wrap: wrap; }
  .services .services-list__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex: 33%;
    margin-bottom: 35px; }
    .services .services-list__item .services-list__img {
      filter: invert(99%) sepia(42%) saturate(7000%) hue-rotate(313deg) brightness(88%) contrast(86%); }
    .services .services-list__item:hover {
      cursor: pointer; }
      .services .services-list__item:hover .services-list__title h4 {
        color: #d1ac56; }
      .services .services-list__item:hover .services-list__img {
        filter: invert(25%) sepia(15%) saturate(2254%) hue-rotate(185deg) brightness(93%) contrast(94%); }
  .services .services-list__title {
    margin-top: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    text-align: center; }
    .services .services-list__title h4 {
      transition: 0.3s all; }

.news .news__row .news__link {
  color: #b89347;
  font-style: italic;
  text-align: center;
  text-decoration: underline;
  margin-top: 28px;
  margin-bottom: 18px;
  display: block; }

.news .news-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 27px; }
  .news .news-list .news-list__item {
    flex: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 63px; }
    .news .news-list .news-list__item:nth-child(3), .news .news-list .news-list__item:nth-child(4) {
      margin-bottom: 0; }
    .news .news-list .news-list__item .news-list__content {
      margin-left: 15px; }
    .news .news-list .news-list__item .news-list__date time {
      text-align: left;
      color: #b89347;
      font-style: italic; }
    .news .news-list .news-list__item .news-list__title {
      height: 60px; }
      .news .news-list .news-list__item .news-list__title h4 {
        margin-top: 24px;
        margin-bottom: 12px;
        font-size: 14px; }

.slick-slide {
  margin-left: 10px; }

.partners-slider {
  padding: 0 100px; }

.partners-slider__item {
  display: flex !important;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center; }
  .partners-slider__item .partners-slider__img {
    height: 130px;
    margin-bottom: 10px; }
  .partners-slider__item .partners-slider__title h4 {
    font-size: 14px;
    text-transform: uppercase; }

.partners.section .section-title {
  left: 50%;
  margin-left: -85px; }

.map__row {
  margin: 40px 0;
  width: 100%; }
  .map__row iframe {
    width: 100%;
    height: 400px; }

.footer__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }

.footer__content {
  display: flex; }

.footer__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .footer__info > div {
    flex: 1; }

.footer .contact-block {
  justify-content: space-between;
  margin-bottom: 20px; }

.footer .contact-block__item {
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }
  .footer .contact-block__item.mobile {
    display: none; }
  .footer .contact-block__item a {
    margin-left: 10px; }
  .footer .contact-block__item:first-child {
    margin-left: 0; }
  .footer .contact-block__item .phone_number span {
    font-size: 24px; }

.footer .main-nav .main-list .main-list__item .main-sublist {
  bottom: 57px;
  top: auto; }

@media screen and (max-width: 1240px) {
  .container {
    width: 95%; }
  .header__row, .footer__row {
    flex-direction: column;
    align-items: center; }
  .contact-block {
    margin: 15px 0;
    justify-content: center; } }

@media screen and (max-width: 1070px) {
  .header__content {
    width: 100%; }
  .header__info {
    width: 100%; }
  .main-nav {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .main-nav .subscribe {
      width: 100%; } }

@media screen and (max-width: 1000px) {
  .about .about__row {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center; }
    .about .about__row .about__img {
      margin-bottom: 15px; }
    .about .about__row .read_more {
      margin: 0 auto; }
  .about .about__content {
    padding-left: 0; }
  .news .news-list {
    flex-direction: column; }
    .news .news-list .news-list__item {
      margin-bottom: 27px; }
      .news .news-list .news-list__item .news-list__title h4 {
        margin-top: 12px; }
      .news .news-list .news-list__item .news-list__content {
        text-align: center;
        padding-top: 15px; }
        .news .news-list .news-list__item .news-list__content .read_more {
          margin: 0 auto; } }

@media screen and (max-width: 768px) {
  .burger-mobile {
    display: block; }
  .footer__logo {
    display: none; }
  .footer .contact-block {
    flex-direction: column-reverse;
    text-align: center;
    justify-content: center;
    align-items: center; }
    .footer .contact-block .contact-block__item {
      margin-bottom: 15px;
      margin-left: 0; }
      .footer .contact-block .contact-block__item:last-child {
        margin-bottom: 0; }
  .footer .subscribe {
    display: none; }
    .footer .subscribe.mobile {
      display: block; }
  .about__img img {
    width: 100%; }
  .services .services-list__item {
    flex: 1; }
  .partners-slider {
    padding: 0 50px; }
  .slick-next {
    right: 0px; }
  .slick-prev {
    left: 0px; }
  .contact-block .contact-block__item.email, .contact-block .contact-block__item.adress {
    display: none; }
  .main-nav .main-list {
    display: none;
    width: 100%; }
    .main-nav .main-list .main-list__item .main-sublist {
      top: 10px;
      width: 100%;
      position: relative; }
  .header__logo.big {
    display: none; }
  .header__logo.small {
    display: block; }
  .header__info > div {
    align-items: flex-end; } }

@media screen and (max-width: 568px) {
  .header__logo.small img {
    width: 114px;
    height: 77px; }
  .services .services-list__item {
    flex: 100%; }
  .news .news-list .news-list__item {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .news .news-list .news-list__item:nth-child(3) {
      margin-bottom: 27px; } }
