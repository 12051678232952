@media screen and (max-width:1240px){
    .container{
        width: 95%;
    }
    .header__row,.footer__row{
        flex-direction: column;
        align-items: center;
    }
    .contact-block{
        margin: 15px 0;
        justify-content: center;
    }
}
@media screen and (max-width:1070px){
    .header__content{
        width: 100%;
    }
    .header__info{
        width: 100%;
    }
    .main-nav{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .subscribe{
            width: 100%;
        }
    }
}
@media screen and (max-width:1000px){
    .about{
        .about__row{
            flex-direction: column;
            justify-content: center;
            align-content: center;
            text-align: center;
            .about__img{
                margin-bottom: 15px;
            }
            .read_more{
                margin: 0 auto;
            }
        }
        .about__content{
            padding-left: 0;
        }
    }
    .news{
        .news-list{
            flex-direction: column;
            .news-list__item{
                margin-bottom: 27px;
                .news-list__title{
                    h4{
                        margin-top:12px;
                    }
                }
                .news-list__content{
                    text-align:center;
                    padding-top: 15px;
                    .read_more{
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:768px){
    .burger-mobile{
        display: block;
    }
    .footer__logo{
        display: none;
    }
    .footer{
        .contact-block{
            flex-direction: column-reverse;
            text-align: center;
            justify-content: center;
            align-items: center;
            .contact-block__item{
                margin-bottom: 15px;
                margin-left: 0;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .subscribe{
            display: none;
            &.mobile{
                display: block;
            }
        }
    }
    .about__img{
        img{
            width: 100%;
        }
    }
    .services{
        .services-list__item{
            flex:1;
        }
    }
    .partners-slider{
        padding: 0 50px;
    }
    .slick-next{
        right: 0px;
    }
    .slick-prev{
        left: 0px;
    }
    .contact-block{
        .contact-block__item.email,.contact-block__item.adress{
            display: none;
        }
    }
    .main-nav{
        .main-list{
            display: none;
            width: 100%;
            .main-list__item{
                .main-sublist{
                    top:10px;
                    width: 100%;
                    position: relative;
                }
            }
        }
    }
    .header__logo.big{
        display: none;
    }
    .header__logo.small{
        display: block;
    }
    .header__info{
        >div{
            align-items: flex-end;
        }
    }
}
@media screen and (max-width:568px){
    .header__logo.small{
        img{
            width: 114px;
            height: 77px;
        }
    }
    .services{
        .services-list__item{
            flex:100%;
        }
    }
    .news{
        .news-list{
            .news-list__item{
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:nth-child(3){
                    margin-bottom: 27px;
                }
            }
        }
    }
}