.slick-slide{
    margin-left: 10px;
}
.partners-slider{
    padding:0 100px;
}
.partners-slider__item{
    display: flex !important;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    .partners-slider__img{
        height: 130px;
        margin-bottom: 10px;
    }
    .partners-slider__title{
        h4{
            font-size: 14px;
            text-transform: uppercase;
        }
    }
}
.partners.section{
    .section-title{
        left: 50%;
        margin-left: -85px;
    }
}