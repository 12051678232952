.footer__row{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.footer__content{
    display: flex;
}
.footer__info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    >div{
        flex:1;
    }
}
.footer{
    .contact-block{
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .contact-block__item{
        margin-left:auto;
        display:flex;
        justify-content: flex-start;
        align-items: flex-end;
        &.mobile{
            display: none;
        }
        a{
            margin-left: 10px;
        }
        &:first-child{
            margin-left: 0;
        }
        .phone_number{
            span{
                font-size: 24px;
            }
        }
    }
}
.footer{
    .main-nav{
        .main-list{
            .main-list__item{
                .main-sublist{
                    bottom: 57px;
                    top:auto;
                }
            }
        }
    }
}