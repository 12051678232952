.about{
    .about__row{
        padding-top:50px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
    .about__content{
        padding-left: 27px;
        .about__img{
            max-width: 100%;
        }
        .about__title{
            margin-bottom: 15px;
        }
        .quote{
            color: #b89347;
            font-size: 15px;
            font-weight: 700;
            font-style: italic;
            margin: 22px 0;
            display: block;
        }
    }
}